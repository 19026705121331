import { navigate } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { ProjectTitle } from '../../components/dx-hr-dev-components/constants';

const Wrapper = styled.div`
  background: #f5f3ec;
`;

const Header = styled.div`
  box-sizing: border-box !important;
  background: #fff;
  height: 120px;
  padding: 48px 0;
  color: #52463c;
`;

const HeaderContainer = styled.div`
  margin: 0 auto;
  padding-left: 16px;
  max-width: 1135px;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 32px;
  line-height: 36px;
  color: #52463c;
  @media (max-width: 700px) {
    font-size: 20px;
  }
`;

const Container = styled.div`
  margin: 0 auto;
  max-width: 1135px;
  height: calc(100vh - 120px);
`;

const Heading = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  padding: 16px 0;
`;

const MessageBox = styled.div`
  width: 80%;
  background: #fff;
  text-align: center;
  color: #1a1007;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  padding: 80px 0;
  margin: 80px auto;
  @media (max-width: 700px) {
    width: 90%;
    padding: 60px 40px;
    text-align: left;
    box-sizing: border-box !important;
    margin: 40px auto;
  }
  @media (max-width: 400px) {
    width: 90%;
    padding: 40px 20px;
    text-align: left;
    box-sizing: border-box !important;
  }
`;

const Message = styled.div`
  padding: 16px 0;
  color: #1a1007;
  font-size: 16px;
  line-height: 24px;
  p {
    font-weight: normal;
    padding: 4px 0;
    white-space: pre-wrap;
  }
  a {
    color: #1e89d7;
    text-decoration: none;
  }

  @media (max-width: 580px) {
    p {
      padding: 0;
      white-space: inherit;
    }
  }
  @media (max-width: 400px) {
    font-size: 14px;
    line-height: 24px;
  }
`;

const BlueText = styled.p`
  color: #1e89d7;
  text-decoration: none;
  font-weight: normal;
  padding-top: 18px;
  cursor: pointer;
`;

const DXEmailSent = () => {
  return (
    <Wrapper>
      <Header>
        <HeaderContainer>{ProjectTitle} </HeaderContainer>
      </Header>

      <Container>
        <MessageBox>
          <Heading>お問い合わせありがとうございました。</Heading>
          <Message>
            <p>
              メールにて資料をお送りいたしますので{'\n'}{' '}
              今しばらくお待ちください。
            </p>
          </Message>
          <BlueText onClick={() => navigate('/dx-hr-development')}>
            TOPへ戻る
          </BlueText>
        </MessageBox>
      </Container>
    </Wrapper>
  );
};

export default DXEmailSent;
